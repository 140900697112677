import { Button, Grid, IconButton } from "@mui/material";
import { Fragment, useMemo } from "react";
import { DeleteIcon } from "../../../../../../../globalTheme/icons/icons";
import TextFieldWithLabel from "../TextFieldWithLabel";

export default function EnumSchemaFields({
  enums,
  onChange
}: {
  enums: string[];
  onChange(enums: string[]): void;
}) {
  const clonedEnums: string[] = useMemo(() => {
    return enums ? JSON.parse(JSON.stringify(enums)) : [];
  }, [enums]);

  return (
    <Fragment>
      {clonedEnums.map((enumOption, index) => (
        <Grid key={index} item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <TextFieldWithLabel
                fullWidth
                size="small"
                label={`Preset option #${index + 1}`}
                placeholder={`Enter preset option ${index + 1}`}
                variant="outlined"
                value={enumOption}
                onChange={(event) => {
                  clonedEnums[index] = event.target.value;
                  onChange(clonedEnums);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ borderRadius: 1, marginTop: "30px" }}
                onClick={() => {
                  const filteredEnums = clonedEnums.filter(
                    (_, i) => i !== index
                  );
                  onChange(filteredEnums);
                }}
              >
                <DeleteIcon color="#12172961" size={14} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="action"
          sx={(theme) => ({ color: theme.palette.primary.main })}
          onClick={() => {
            clonedEnums.push("");
            onChange(clonedEnums);
          }}
        >
          + Add preset option
        </Button>
      </Grid>
    </Fragment>
  );
}
