import {
  FieldType,
  type FieldConfig,
  type StructuredBlockProps
} from "@toolflow/shared";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../globalTheme/muiUtils/appTheme";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";

function StructuredBlockInner({
  data,
  id
}: Omit<StructuredBlockProps, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.PromptInput,
        label: "Source Text",
        fieldKey: "input",
        maxRows: MAX_ROWS_LARGE_TEXTFIELD,
        minRows: 1
      },
      {
        type: FieldType.JsonSchemaInput,
        label: "Configuration",
        subLabel: "Define what information to extract"
      }
    ]
  };

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType id={id} settings={settings} configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default StructuredBlockInner;
