import { Typography } from "@mui/material";
import { BlockContextProvider } from "../../../../BlockContext";
import { blockExpandedComponentDict } from "../../../../blocks/constants/blockConfig";
import useGetCurrentBlock from "../../../../store/hooks/useGetCurrentBlock";
import { DrawerHeader } from "../../drawerHeader/DrawerHeaderContainer";
import isRunnableBlock from "../../../functions/isRunnableBlock";
import { useBlockIcon } from "../../../../blocks/components/blockIcons/BlockIcon";
import { setDrawerExpandedOpen } from "../../../../store/toolBuilderSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import type { TBlock } from "@toolflow/shared";
import ExpandDrawerDialogButton from "../expandDrawerDialog/ExpandDrawerDialogButton";

const useNodeExpandedContent = () => {
  const currentBlock = useGetCurrentBlock();
  const BlockIcon = useBlockIcon(currentBlock?.data);

  return {
    currentBlock: currentBlock,
    BlockIcon
  };
};

const useSetDrawerClosedIfNotExpandable = ({
  currentBlock
}: {
  currentBlock: TBlock | null;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      isRunnableBlock(currentBlock) &&
      !blockExpandedComponentDict[currentBlock.type]
    ) {
      dispatch(setDrawerExpandedOpen(false));
    }
  }, [currentBlock]);
};

const NodeExpandedContent = () => {
  const { currentBlock, BlockIcon } = useNodeExpandedContent();
  useSetDrawerClosedIfNotExpandable({ currentBlock });

  const Component = isRunnableBlock(currentBlock)
    ? blockExpandedComponentDict[currentBlock.type]
    : null;
  if (currentBlock == null || !Component) {
    return <></>;
  }

  return (
    <BlockContextProvider
      id={currentBlock.id}
      data={currentBlock.data}
      selected={true}
    >
      <DrawerHeader
        BlockIcon={BlockIcon && <BlockIcon />}
        title={<Typography variant="h8">Test panel</Typography>}
        rightSlot={<ExpandDrawerDialogButton />}
      />
      <Component data={currentBlock.data} id={currentBlock.id} />
    </BlockContextProvider>
  );
};

export default NodeExpandedContent;
