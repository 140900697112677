import {
  Block,
  BlockNode,
  BlockType,
  UtilBlockType,
  type BlockDataTypes,
  type BlockDataTypesWithDefault,
  type DeepgramBlockData,
  type PerplexityBlockData,
  type PromptBlockData,
  type ScraperBlockData,
  type SerpBlockData,
  type TBlock
} from "@toolflow/shared";
import { cloneDeep } from "lodash";
import React from "react";
import {
  ConstantIcon,
  CrawlerIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  LogicIcon,
  SpeechToTextFillIcon,
  ToolIcon
} from "../../../../../../globalTheme/icons/icons";
import PerplexityLogo from "../../../../../../globalTheme/icons/logos/PerplexityLogo";
import {
  dallEPromptInitialState,
  deepgramTranscribePromptInitialState,
  promptInitialState,
  scraperSettingsInitialState,
  serpSettingsInitialState
} from "../../context/initialStates";
import { perplexityInitialState } from "../../context/initialStates/perplexityInitialState";
import { structuredInitialState } from "../../context/initialStates/structuredInitialState";
import ConstantBlockInner from "../constantBlock/ConstantBlockInner";
import DeepgramBlockInner from "../deepgram/DeepgramBlockInner";
import TestDeepgram from "../deepgram/TestDeepgram";
import LogicInner from "../logic/LogicInner";
import PerplexityBlockInner from "../perplexityBlock/PerplexityBlockInner";
import TestPerplexity from "../perplexityBlock/TestPerplexity";
import PromptBlockInner from "../promptBlocks/PromptBlockInner";
import TestPrompt from "../promptBlocks/TestPrompt";
import ScraperBlockInner from "../scraperBlock/ScraperBlockInner";
import TestScraper from "../scraperBlock/TestScraper";
import SerpBlockInner from "../serpBlock/SerpBlockInner";
import TestSerp from "../serpBlock/TestSerp";
import StructuredBlockInner from "../structuredBlock/StructuredBlockInner";
import TestStructured from "../structuredBlock/TestStructured";
import ToolWithinToolBlockInner from "../toolWithinTool/ToolWithinToolBlockInner";
import AnthropicLogo from "../../../../../../globalTheme/icons/logos/AnthropicLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";
import GoogleLogo from "../../../../../../globalTheme/icons/logos/GoogleLogo";

type Item = Omit<TBlock, "id" | "position" | "data" | "parentNode"> & {
  data: BlockDataTypes;
};

export const blockConfig: Record<
  Block,
  {
    draggableItem: Item;
    icon: React.ElementType;
    blockPaperLabel: string;
    drawerComponent: React.ElementType;
    expandedComponent?: React.ElementType;
  }
> = {
  [Block.logic]: {
    icon: LogicIcon,
    drawerComponent: LogicInner,
    blockPaperLabel: "Logic",
    draggableItem: {
      type: BlockNode.LogicBlockNode,
      data: {
        label: "Logic",
        type: UtilBlockType.Logic,
        logicArray: []
      }
    }
  },
  [Block.constant]: {
    icon: ConstantIcon,
    drawerComponent: ConstantBlockInner,
    blockPaperLabel: "Text composer",
    draggableItem: {
      type: BlockNode.ConstantBlockNode,
      data: {
        label: "Text",
        type: UtilBlockType.Constant,
        constant: ""
      }
    }
  },
  [Block.textGeneration]: {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    blockPaperLabel: "Text generator",
    icon: OpenAILogo,
    draggableItem: {
      type: BlockNode.PromptBlockNode,
      data: {
        type: BlockType.ChatGPT,
        label: "LLM output",
        ...promptInitialState
      } as PromptBlockData
    }
  },
  [Block.imageGeneration]: {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    blockPaperLabel: "Image generator",
    icon: ImageGenerationIcon,
    draggableItem: {
      type: BlockNode.PromptBlockNode,
      data: {
        type: BlockType.DallE,
        label: "Image Output Name",
        ...dallEPromptInitialState
      } as PromptBlockData
    }
  },
  [Block.speechToText]: {
    icon: SpeechToTextFillIcon,
    drawerComponent: DeepgramBlockInner,
    expandedComponent: TestDeepgram,
    blockPaperLabel: "Speech to text",
    draggableItem: {
      type: BlockNode.DeepgramBlockNode,
      data: {
        ...deepgramTranscribePromptInitialState,
        type: BlockType.Deepgram,
        label: "Transcript"
      } as DeepgramBlockData
    }
  },
  [Block.scraper]: {
    icon: CrawlerIcon,
    expandedComponent: TestScraper,
    drawerComponent: ScraperBlockInner,
    blockPaperLabel: "Web scraper",
    draggableItem: {
      type: BlockNode.ScraperBlockNode,
      data: {
        ...scraperSettingsInitialState,
        type: BlockType.Scraper,
        label: "Website content"
      } as ScraperBlockData
    }
  },
  [Block.serp]: {
    icon: GoogleLogo,
    drawerComponent: SerpBlockInner,
    expandedComponent: TestSerp,
    blockPaperLabel: "Google search",
    draggableItem: {
      type: BlockNode.SerpBlockNode,
      data: {
        ...serpSettingsInitialState,
        type: BlockType.SERP,
        label: "Google Search Results"
      } as SerpBlockData
    }
  },
  [Block.structured]: {
    icon: DataExtractorIcon,
    expandedComponent: TestStructured,
    drawerComponent: StructuredBlockInner,
    blockPaperLabel: "Data extractor",
    draggableItem: {
      type: BlockNode.StructuredBlockNode,
      data: {
        ...cloneDeep(structuredInitialState),
        type: BlockType.Structured,
        label: "Data extractor"
      }
    }
  },
  [Block.perplexity]: {
    icon: PerplexityLogo,
    drawerComponent: PerplexityBlockInner,
    expandedComponent: TestPerplexity,
    blockPaperLabel: "Perplexity",
    draggableItem: {
      type: BlockNode.PerplexityBlockNode,
      data: {
        ...perplexityInitialState,
        type: BlockType.Perplexity,
        label: "Perplexity research"
      } as PerplexityBlockData
    }
  },
  [Block.embedded]: {
    icon: ToolIcon,
    drawerComponent: ToolWithinToolBlockInner,
    blockPaperLabel: "Embedded Tool",
    draggableItem: {
      type: BlockNode.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: UtilBlockType.ToolWithinTool,
        tool: null,
        inputMap: null
      }
    }
  }
};

export const blockHeaderIconDict: Record<
  BlockDataTypesWithDefault["type"],
  React.ElementType
> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.data.type,
      value.icon
    ])
  ),
  Anthropic: AnthropicLogo, // we need to fix this -> the promptblock, anthropic, chatgpt, dall-e should be structured better
  default: OpenAILogo
} as Record<BlockDataTypesWithDefault["type"], React.ElementType>;

export const blockPaperLabelDict: Record<
  BlockDataTypesWithDefault["type"],
  string
> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.data.type,
      value.blockPaperLabel
    ])
  ),
  Anthropic: "Text generation", // we need to fix this -> the promptblock, anthropic, chatgpt, dall-e should be structured better
  default: "Text generation"
} as Record<BlockDataTypesWithDefault["type"], string>;

export const blockComponentDict: Record<BlockNode, React.ElementType> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.type,
      value.drawerComponent
    ])
  )
} as Record<BlockNode, React.ElementType>;

export const blockExpandedComponentDict: Record<BlockNode, React.ElementType> =
  {
    ...Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(blockConfig).map(([_, value]) => [
        value.draggableItem.type,
        value.expandedComponent
      ])
    )
  } as Record<BlockNode, React.ElementType>;
