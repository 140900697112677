import type { BlockDataTypesWithDefault } from "@toolflow/shared";
import { blockHeaderIconDict } from "../../constants/blockConfig";
import styles from "./blockIcon.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

export const useBlockIcon = (data?: BlockDataTypesWithDefault) => {
  if (!data) return null;
  const { type } = data;
  if (type === "default") {
    return null;
  }

  return blockHeaderIconDict[type] as React.ElementType | null;
};

const BlockIconWrapper = ({
  BlockIcon,
  className
}: {
  BlockIcon: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx(styles["icon-with-circle"], className)}>{BlockIcon}</div>
  );
};

export default BlockIconWrapper;
