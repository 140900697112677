import { Stack, Typography } from "@mui/material";
import Slider, { type SliderOwnProps } from "@mui/material/Slider";
import useUpdateField from "../../../useUpdateField";

interface Props {
  id: string;
  value: number;
  label: string;
  subtitle?: string;
  fieldKey: string;
}

export default function SliderField({
  id,
  value,
  fieldKey,
  label,
  subtitle
}: Props) {
  const updateField = useUpdateField(id);

  const onChange: SliderOwnProps["onChange"] = (_, newValue) => {
    updateField(newValue as number, fieldKey);
  };

  return (
    <>
      <Stack spacing={2}>
        <div>
          <Typography variant="h8">{label}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </div>
        <Slider
          className="nowheel nodrag nopan"
          value={value}
          onChange={onChange}
          marks={[{ value: 50 }]}
        />
      </Stack>
    </>
  );
}
