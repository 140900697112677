import type {
  CustomToolInputField,
  CustomToolOutputField,
  TBlock,
  ToolOutputOption,
  UniqueNameOutput
} from "@toolflow/shared";
import { BlockNode, CopyableField } from "@toolflow/shared";
import { PromptTypeToOutputFieldMap } from "../../../../tools/components/editorToolCard/inputs/helpers/outputFieldMaps";

export const getUniqueOutputNames = (
  customToolInputFields: CustomToolInputField[],
  nodes: TBlock[]
): UniqueNameOutput => {
  // Extract names from customToolInputFields
  const inputFieldNames = customToolInputFields
    .filter((field) => field.name && field.name.trim() !== "")
    .map((field) => {
      return {
        value: field.name,
        type: CopyableField.LargeText
      } as ToolOutputOption;
    });

  const blockFieldNames: ToolOutputOption[] = [];

  nodes.forEach((node) => {
    if (
      node.type === BlockNode.ToolWithinToolBlockNode ||
      node.type === BlockNode.PromptBlockNode ||
      node.type === BlockNode.ConstantBlockNode ||
      node.type === BlockNode.DeepgramBlockNode ||
      node.type === BlockNode.ScraperBlockNode ||
      node.type === BlockNode.SerpBlockNode ||
      node.type === BlockNode.StructuredBlockNode ||
      node.type === BlockNode.PerplexityBlockNode
    ) {
      if (node.type !== BlockNode.ToolWithinToolBlockNode) {
        blockFieldNames.push({
          value: node.data.label,
          type: PromptTypeToOutputFieldMap[
            node.data.type as keyof typeof PromptTypeToOutputFieldMap // Todo: Could be better
          ]
        });
      } else {
        // i think there is a bug where
        node.data.tool?.main.toolOutputFields.forEach(
          (field: CustomToolOutputField) => {
            blockFieldNames.push({
              value: `${node.data.label} - ${field.name}`,
              type: field.type
            });
          }
        );
      }
    }
  });

  const filteredInputs = inputFieldNames.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );

  const filteredBlocks = blockFieldNames.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );

  return { inputs: filteredInputs, blocks: filteredBlocks };
};
