import { useEffect, useMemo, useState } from "react";
import type {
  DallEOutputType,
  Image,
  TWorkspacePartial,
  ValidateType,
  WorkflowToolInputs
} from "@toolflow/shared";
import {
  DEFAULT_WORKSPACE,
  TOOL_TAB_BASE,
  WORKFLOW_USE_BASE,
  WORKSPACE_ENTITY_BASE,
  WORKSPACE_INPUTS_FIELD_LABEL
} from "../helpers/workspaceConstants";
import { v4 as uuidv4 } from "uuid";
import { useGetToolVersionResponseQuery } from "../../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { getHtmlFromText } from "../../../../utilities/formatters/strings/sanitizeHtml";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../stores/store";
import { clearInitialObject } from "../state/workspaceSlice";
import { TOOL, WORKFLOW } from "../../../../utilities/constants/constants";
import { getWorkspaceInputTypeFromValue } from "../helpers/workspaceInputType";

const useGetToolTab = ({ skip, id }: { skip?: boolean; id: string }) => {
  const componentId = useMemo(() => uuidv4(), [id]);
  if (!skip) {
    return {
      [WORKSPACE_ENTITY_BASE]: {
        entityId: id,
        componentId,
        entityType: TOOL
      },
      [TOOL_TAB_BASE]: {
        userInput: {}
      }
    };
  }
  return undefined;
};

const useGetTVRTab = ({ skip, id }: { skip?: boolean; id: string }) => {
  const { data, isLoading } = useGetToolVersionResponseQuery(id, skip || !id);

  function isDallEOutputType(value: unknown): value is DallEOutputType {
    if (!Array.isArray(value)) {
      return false;
    }

    return value.every((item) => {
      if (typeof item !== "object" || item === null) {
        return false;
      }

      const { b64_json, revised_prompt, url } = item as Image; // eslint-disable-line @typescript-eslint/naming-convention

      const hasValidB64Json =
        b64_json === undefined || typeof b64_json === "string";
      const hasValidRevisedPrompt =
        revised_prompt === undefined || typeof revised_prompt === "string";
      const hasValidUrl = url === undefined || typeof url === "string";

      return hasValidB64Json && hasValidRevisedPrompt && hasValidUrl;
    });
  }

  // Compute input objects with memoized IDs only when data changes
  const inputObject = useMemo(() => {
    if (!data?.toolVersionResponse) return [];

    return (
      Object.entries(data.toolVersionResponse.responseDict)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([name, val]) => {
          return typeof val === "string" || isDallEOutputType(val);
        })
        .map(([name, value]) => {
          const inputId = uuidv4(); // Generate new UUID only if the responseDict changes
          return {
            name,
            value: typeof value === "string" ? getHtmlFromText(value) : value,
            id: inputId,
            source: "toolOutput",
            valueType: getWorkspaceInputTypeFromValue(value)
          } as WorkflowToolInputs;
        })
    );
  }, [data?.toolVersionResponse?.responseDict]);
  return useMemo(() => {
    if (data?.toolVersionResponse) {
      return {
        [WORKSPACE_INPUTS_FIELD_LABEL]: inputObject
      };
    }
    return undefined;
  }, [isLoading, inputObject]);
};

const useGetWorkflow = ({ skip, id }: { skip?: boolean; id: string }) => {
  const componentId = useMemo(() => uuidv4(), [id]);
  if (!skip) {
    return {
      [WORKSPACE_ENTITY_BASE]: {
        entityId: id,
        componentId,
        entityType: WORKFLOW
      },
      [WORKFLOW_USE_BASE]: {
        activeStep: 0,
        workflowProgress: {}
      }
    };
  }
  return undefined;
};

const useSetDefaultObject = () => {
  const defaultObject = useSelector(
    (state: RootState) => state.workspace.initialObject
  );

  const tvr = useGetTVRTab({
    skip: defaultObject?.type !== "toolVersionResponse",
    id: defaultObject?.id || ""
  });

  const tool = useGetToolTab({
    skip: defaultObject?.type !== "tool",
    id: defaultObject?.id || ""
  });

  const workflow = useGetWorkflow({
    skip: defaultObject?.type !== "workflow",
    id: defaultObject?.id || ""
  });

  return useMemo(() => {
    return {
      object: tvr || tool || workflow || undefined,
      type: defaultObject?.type
    };
  }, [tvr, tool, workflow]);
};

const useDefaultWorkspaceFormValues = () => {
  const { object, type } = useSetDefaultObject();

  const dispatch = useDispatch();

  const [parsedObject, setParsedObject] = useState<TWorkspacePartial>(
    object ?? {}
  );
  const [parsedType, setParsedType] = useState<ValidateType | undefined>(
    type ?? undefined
  );

  useEffect(() => {
    if (object) {
      dispatch(clearInitialObject());
      setParsedObject({
        ...DEFAULT_WORKSPACE,
        ...object
      });
      setParsedType(type);
    }
  }, [JSON.stringify(object)]);

  return {
    parsedObject,
    type: parsedType
  };
};

export default useDefaultWorkspaceFormValues;
