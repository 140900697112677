import {
  type BlockMessageType,
  type CustomEdge,
  type LogicItem,
  type TBlock,
  BlockNode,
  BlockType
} from "@toolflow/shared";

function setBlockMessage(
  block: TBlock | undefined,
  edges: CustomEdge[]
): BlockMessageType | undefined {
  if (!block) return undefined;
  if (
    block.type === BlockNode.DeepgramBlockNode &&
    [BlockType.Deepgram].includes(block.data.type) &&
    block.data.settings.file === ""
  ) {
    return { severity: "error", message: `No mapped file to transcribe.` };
  }
  if (
    block.type === BlockNode.ScraperBlockNode &&
    [BlockType.Scraper].includes(block.data.type) &&
    block.data.settings.urlFieldInputKey === ""
  ) {
    return { severity: "error", message: `No mapped url to scrape` };
  }
  if (
    block.type === BlockNode.ToolWithinToolBlockNode &&
    block.data.inputMap &&
    Object.values(block.data.inputMap).some((value) => value === "")
  ) {
    return { severity: "warning", message: `Not all inputs are mapped.` };
  }
  if (block.type === BlockNode.ConstantBlockNode && !block.data.constant) {
    return {
      severity: "error",
      message: `A constant must have a value.`
    };
  }
  if (block.type === BlockNode.LogicBlockNode) {
    if (
      block.data?.logicArray.filter((item: LogicItem) => item.input !== "")
        .length === 0
    )
      return {
        severity: "error",
        message: `Logic blocks can't be empty. Add a filter.`
      };
    if (!edges.some((e) => e.source === block.id)) {
      return {
        severity: "error",
        message: `Logic blocks must connect their output to another block. Delete this block if it is unused.`
      };
    }
    if (
      block.data?.logicArray.some(
        (logic: LogicItem) => logic.parameterValue === ""
      )
    ) {
      return {
        severity: "error",
        message: `Logic filters need to be fully filled out.`
      };
    }
  }

  // If no errors were found, return null
  return undefined;
}

export default setBlockMessage;
