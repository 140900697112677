import { ListItemText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type SelectInputProps } from "@mui/material/Select/SelectInput";
import Typography from "@mui/material/Typography";
import { type SelectOption } from "@toolflow/shared";
import { isString } from "lodash";
import useUpdateField from "../../../useUpdateField";

interface Props {
  id: string;
  value: string;
  fieldKey: string;
  label: string;
  subtitle?: string;
  options: SelectOption[];
}

export default function NewSelectField({
  id,
  value,
  fieldKey,
  label,
  options,
  subtitle
}: Props) {
  const updateField = useUpdateField(id);

  const onChange: SelectInputProps["onChange"] = (event) => {
    updateField(event.target.value as string, fieldKey);
  };

  return (
    <FormControl fullWidth margin="normal">
      <Stack spacing={2}>
        <div>
          <Typography variant="h8">{label}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </div>
        <Select
          size="small"
          value={value}
          className="nowheel nodrag nopan"
          onChange={onChange}
        >
          {options.map((option, i) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={i}>
                  <ListItemText>{option}</ListItemText>
                </MenuItem>
              );
            }

            return (
              <MenuItem value={option.value} key={option.value}>
                <ListItemText>
                  <Stack direction="row" alignItems="center">
                    <Typography>{option.label}&nbsp;</Typography>
                    {option.subText && (
                      <Typography variant="body2" color="text.secondary">
                        {option.subText}
                      </Typography>
                    )}
                  </Stack>
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    </FormControl>
  );
}
