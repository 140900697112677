import DrawerBottom from "../../drawerBottom/DrawerBottom";
import BackToOutputsButton from "./BackToOutputsButton";
import DeleteToolOutputFieldButton from "./customToolOutputFields/DeleteToolOutputFieldButton";
import ChangeToolOutputNameSelectContainer from "./customToolOutputFields/ChangeToolOutputNameSelectContainer";
import { ToolbuilderOutputFieldContextProvider } from "./customToolOutputFields/ToolbuilderOutputFieldContext";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import BackHeader from "../utilities/BackHeader";
import OutputsDoneButton from "./OutputsDoneButton";

const OutputDetails = ({ outputId }: { outputId: string }) => {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  const selectedOutputField = toolOutputFields.find(
    (field) => field.id === outputId
  );

  if (!selectedOutputField) return null;

  return (
    <ToolbuilderOutputFieldContextProvider
      toolOutputField={selectedOutputField}
    >
      <DrawerBottom
        mainContent={
          <>
            <BackHeader button={<BackToOutputsButton />} />
            <div className="p-16px">
              <ChangeToolOutputNameSelectContainer />
            </div>
          </>
        }
        leftSlot={<DeleteToolOutputFieldButton />}
        rightSlot={<OutputsDoneButton />}
      />
    </ToolbuilderOutputFieldContextProvider>
  );
};

export default OutputDetails;
