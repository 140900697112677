import { Box, Divider } from "@mui/material";
import {
  BlockNode,
  type PerplexityBlockData,
  type PerplexityBlockProps
} from "@toolflow/shared";
import { Fragment, useMemo } from "react";
import { PromptTypeToOutputFieldMap } from "../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldMaps";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../common/TestToolRun";
import { getPromptWithUserInput } from "../../common/utils";
import EmptyTestBlock from "../components/EmptyTestBlock";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";
import TestFields from "../promptBlocks/TestFields";

export default function TestPerplexity({
  data,
  id
}: Omit<PerplexityBlockProps, "selected">) {
  const { settings, type } = data;
  const isReady = !!settings.input.length;
  const {
    action,
    setUserInput,
    userInput,
    label,
    text,
    endIcon,
    showUpgrade,
    loading,
    output,
    fields
  } = useTest<PerplexityBlockData>({
    data,
    id,
    blockType: BlockNode.PerplexityBlockNode,
    fieldInput: settings.input
  });

  const filledOutPrompt = useMemo(
    () => getPromptWithUserInput(settings.input, userInput),
    [settings.input, userInput]
  );

  return (
    <DrawerBottom
      mainContent={
        <Fragment>
          {isReady ? (
            <Fragment>
              <TestFields
                fields={fields}
                uI={userInput}
                setUI={setUserInput}
                filledOutPrompt={filledOutPrompt}
              />
              <Divider />
              <Box sx={{ marginInline: 2, marginTop: 2, paddingBottom: 2 }}>
                <TestBlockOutput
                  output={output}
                  label={label}
                  type={PromptTypeToOutputFieldMap[type]}
                />
              </Box>
            </Fragment>
          ) : (
            <EmptyTestBlock
              text="Output preview area"
              subText="Test your node here."
            />
          )}
        </Fragment>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          disabled={!isReady}
          loading={loading}
        />
      }
    />
  );
}
