export enum FieldType {
  TextInput = "free-form-input",
  SelectInput = "select-input",
  CustomSelectInput = "custom-select-input",
  JsonSchemaInput = "json-schema-input",
  PromptInput = "prompt-input",
  Slider = "slider",
  NewSelectInput = "new-select-input"
}

export enum PrimaryOptionsDict {
  settings = "settings",
  optimizations = "optimizations"
}

export type SelectOption =
  | string
  | { label: string; value: string; subText?: string };

export interface BaseInputFieldProps {
  label: string;
  subtitle?: string;
}

export interface NewSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.NewSelectInput;
  fieldKey: string;
  primaryKey?: PrimaryOptionsDict;
  options: SelectOption[];
}

export interface SelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.SelectInput;
  fieldKey: string;
  options: string[];
}

export interface CustomSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.CustomSelectInput;
  sourceLabel: string;
  fieldKey: string;
  inputValueType?: "string" | "array";
  userFieldKey?: string;
}

export interface TextInputFieldType extends BaseInputFieldProps {
  type: FieldType.TextInput;
  sourceLabel: string;
}

export interface JsonSchemaInputFieldType extends BaseInputFieldProps {
  type: FieldType.JsonSchemaInput;
  subLabel?: string;
}

export interface PromptInputFieldType extends BaseInputFieldProps {
  type: FieldType.PromptInput;
  fieldKey: string;
  minRows: number;
  maxRows: number;
}

export interface SliderFieldType extends BaseInputFieldProps {
  type: FieldType.Slider;
  fieldKey: string;
  primaryKey?: PrimaryOptionsDict;
}

export type FieldTypeConfigs =
  | CustomSelectInputFieldType
  | JsonSchemaInputFieldType
  | TextInputFieldType
  | SelectInputFieldType
  | PromptInputFieldType
  | SliderFieldType
  | NewSelectInputFieldType;

export interface FieldConfig {
  fields: FieldTypeConfigs[];
}
