import {
  type DallEOptimizationsType,
  type DeepgramBlockData,
  type FileReference,
  type IntegrationPrice,
  type OptimizationsType,
  type PromptBlockData,
  type ScraperBlockData,
  type TBlock,
  type TEstimatedAudioLengthInSeconds,
  type UserInputDictType,
  BlockType,
  OpenAiImageModel,
  ServiceReferenceName
} from "@toolflow/shared";
import { useMemo } from "react";
import { useGetIntegrationPricesQuery } from "../../../../../../ToolflowAPI/rtkRoutes/integrationPriceApi";
import useGetCurrentUser from "../../../../../user/hooks/useGetCurrentUser";

const PLEASE_UPLOAD_AUDIO_FILE = "Please upload an audio file to estimate cost";
const UNKNOWN_MODEL_UNABLE_TO_CALCULATE_COST =
  "Unknown model, unable to calculate cost";
const UNKNOWN_SCRAPER = "Unknown scraper, unable to calculate cost";

const SECONDS_IN_HOURS: TEstimatedAudioLengthInSeconds = 3600;
// THIS SHOULD BE ON THE BACKEND TOO
const getEstimatedAudioMaxCost = (
  selectedPriceMaxCost: number,
  audioFileDuration: number = SECONDS_IN_HOURS
) => {
  return (selectedPriceMaxCost * audioFileDuration) / 60; // Prices are per minute
};

function calculateBlockCost({
  data,
  integrationPrices,
  formState
}: {
  data: PromptBlockData | DeepgramBlockData | ScraperBlockData;
  integrationPrices: IntegrationPrice[];
  formState?: UserInputDictType;
}) {
  let cost = 0;
  let costExplanation = "Unable to calculate cost. Unknown block type";
  if (data.type === BlockType.Deepgram) {
    const selectedPrice = integrationPrices.find(
      (i) =>
        i.serviceReferenceName === ServiceReferenceName.Deepgram &&
        i.integrationReferenceName === data.settings.model
    );
    if (selectedPrice) {
      const fileReference =
        formState && (formState[data.settings.file] as FileReference);
      if (fileReference?._id) {
        if (fileReference?.audioFileDuration) {
          cost = getEstimatedAudioMaxCost(
            selectedPrice.maxCreditCost,
            fileReference?.audioFileDuration
          );
          costExplanation = `${cost} credits`;
        } else {
          // in the case where an audio file does not have a duration, we assume one hour.
          // this will only happen in cases in which the audio file was uploaded before the duration field was added.
          cost = getEstimatedAudioMaxCost(selectedPrice.maxCreditCost);
          costExplanation = `${cost} credits`;
        }
      } else {
        // in the case where an audio file does not have a duration, we assume one hour.
        // this will only happen in cases in which the audio file was uploaded before the duration field was added.
        cost = getEstimatedAudioMaxCost(selectedPrice.maxCreditCost);
        costExplanation = PLEASE_UPLOAD_AUDIO_FILE;
      }
    } else {
      costExplanation = UNKNOWN_MODEL_UNABLE_TO_CALCULATE_COST;
    }
  } else if (data.type === BlockType.Scraper) {
    const selectedPrice = integrationPrices.find(
      (i) => i.integrationReferenceName === "web-content-crawler"
    );
    if (selectedPrice) {
      cost = selectedPrice.maxCreditCost * 15;
    } else {
      costExplanation = UNKNOWN_SCRAPER;
    }
  } else if (data.type === BlockType.ChatGPT) {
    const selectedPrice = integrationPrices.find(
      (i) =>
        i.serviceReferenceName === ServiceReferenceName.OpenAI &&
        i.integrationReferenceName ===
          (data.optimizations as OptimizationsType).llmModel
    );
    if (selectedPrice) {
      if (selectedPrice.maxCreditCost === 1) {
        cost = 1;
        costExplanation = `${cost} credit`;
      } else {
        cost = selectedPrice.maxCreditCost;
        costExplanation = `${cost} credits`;
      }
    }
  } else if (data.type === BlockType.DallE) {
    const model = (data.optimizations as DallEOptimizationsType).model;
    const size = (data.optimizations as DallEOptimizationsType).size;
    const quality = (data.optimizations as DallEOptimizationsType).quality;
    const integrationReferenceName =
      model === OpenAiImageModel.DALLE2 ? model : `${model}-${quality}-${size}`;
    const selectedPrice = integrationPrices.find(
      (i) =>
        i.serviceReferenceName === ServiceReferenceName.OpenAI &&
        i.integrationReferenceName === integrationReferenceName
    );
    if (selectedPrice) {
      cost =
        selectedPrice.maxCreditCost *
        (data.optimizations as DallEOptimizationsType).n;
      costExplanation = `${cost} credit${cost === 1 ? "" : "s"}`;
    } else {
      costExplanation = UNKNOWN_MODEL_UNABLE_TO_CALCULATE_COST;
    }
  }

  return {
    cost,
    costExplanation
  };
}

const useBlockCost = (
  data: PromptBlockData | DeepgramBlockData | ScraperBlockData,
  formState?: UserInputDictType
) => {
  const { data: integrationPriceData = { integrationPrices: [] } } =
    useGetIntegrationPricesQuery();
  const integrationPrices = integrationPriceData.integrationPrices;
  const user = useGetCurrentUser();

  return useMemo(
    () => calculateBlockCost({ data, formState, integrationPrices }),
    [
      JSON.stringify(data),
      JSON.stringify(formState),
      integrationPrices,
      user?.toolflowEmployeeType
    ]
  );
};

export const calculateToolCost = (
  blocks: TBlock[],
  payload?: {
    integrationPrices: IntegrationPrice[];
  }
) => {
  let cost = 0;
  const costExplanation = "Unable to calculate cost. Unknown block type";
  if (payload) {
    blocks.forEach((block) => {
      const { cost: blockCost } = calculateBlockCost({
        data: block.data,
        ...payload
      });
      cost += blockCost;
    });
  }
  return { cost, costExplanation };
};

export default useBlockCost;
