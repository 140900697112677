import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useOnConnect from "./useOnConnect";
import useViewports from "./useViewports";
import useOnDrop from "./useOnDrop";
import useCloseDrawer from "../../store/hooks/useCloseDrawer";
import useSetNodeDrawer from "../../store/hooks/useSetNodeDrawer";
import useGetDrawerNodeId from "../../store/hooks/useGetDrawerNodeId";
import BlockWrapper from "../../blocks/components/BlockWrapper";
import { blockConfig } from "../../blocks/constants/blockConfig";
import OutputBlock from "../../blocks/archive/output/OutputBlock";
import InputBlock from "../../blocks/archive/input/InputBlock";
import ParentBlock from "../ParentBlock";
import CustomEdge from "../edges/CustomEdge";
import type {
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
  Edge as ReactflowEdge
} from "reactflow";
import { applyNodeChanges, applyEdgeChanges, useReactFlow } from "reactflow";
import { useCallback, useMemo, useRef } from "react";
import type { TBlock } from "@toolflow/shared";

const useFlow = () => {
  const { state, reactflowUtility } = useToolbuilderContext();
  const reactFlowInstance = useReactFlow();
  const reactFlowWrapper: React.RefObject<HTMLDivElement> = useRef(null);
  const { tempViewport } = state;
  const { blocks: nodes, edges } = state.currentState;
  const { setEdges, setNodes, setDragParams } = reactflowUtility;

  const onConnect = useOnConnect();
  useViewports(reactFlowInstance);

  const onNodesChange: OnNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds) as unknown as TBlock[]),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      return setEdges(
        (edgs) => applyEdgeChanges(changes, edgs as unknown as ReactflowEdge[]),
        true
      );
    },
    [setEdges]
  );

  const nodeTypes = useMemo(() => {
    return {
      outputBlockNode: OutputBlock,
      inputBlockNode: InputBlock,
      parentBlockNode: ParentBlock,
      ...Object.fromEntries(
        Object.values(blockConfig).map((values) => [
          values.draggableItem.type,
          BlockWrapper
        ])
      )
    };
  }, []);

  const edgeTypes = useMemo(() => {
    return {
      buttonedge: CustomEdge
    };
  }, []);

  const markerInfo = {
    markerStart: "purple-circle",
    markerEnd: "marker-end",
    style: {
      strokeWidth: 1
    },
    type: "buttonedge"
  };

  /**
   * This example demonstrates how you can remove the attribution from the React Flow renderer.
   * Please only hide the attribution if you are subscribed to React Flow Pro: https://pro.reactflow.dev
   */
  const proOptions = { hideAttribution: true, account: "example" };

  const onDragOver: React.DragEventHandler<HTMLDivElement> = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const setNodeDrawer = useSetNodeDrawer();
  const closeDrawer = useCloseDrawer();
  const openNodeId = useGetDrawerNodeId();

  const onNodeClick = (event: React.MouseEvent, n: Node) => {
    if (
      ["parentBlockNode", "inputBlockNode", "outputBlockNode"].includes(
        n.type || ""
      )
    ) {
      if (openNodeId !== n.id) {
        closeDrawer();
      }
    } else if (openNodeId !== n.id) {
      setNodeDrawer(n.id);
    }
  };

  const onDrop = useOnDrop(reactFlowInstance, reactFlowWrapper);

  return {
    onConnect,
    onNodesChange,
    onEdgesChange,
    onDragOver,
    onDrop,
    onNodeClick,
    onPaneClick: closeDrawer,
    proOptions,
    setDragParams,
    nodes,
    edges,
    reactFlowWrapper,
    nodeTypes,
    edgeTypes,
    markerInfo,
    defaultViewport: tempViewport || undefined,
    handleEdgeMouseEnter: reactflowUtility.handleEdgeMouseEnter,
    handleEdgeMouseLeave: reactflowUtility.handleEdgeMouseLeave
  };
};

export default useFlow;
