import {
  BlockNode,
  type PromptBlockData,
  type PromptBlockProps
} from "@toolflow/shared";
import { useMemo } from "react";
import { PromptTypeToOutputFieldMap } from "../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldMaps";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../common/TestToolRun";
import { getPromptWithUserInput } from "../../common/utils";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";
import TestFields from "./TestFields";

const TestPrompt = ({ data, id }: Omit<PromptBlockProps, "selected">) => {
  const { prompt, type } = data;

  const {
    action,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    fields,
    userInput,
    setUserInput,
    loading
  } = useTest<PromptBlockData>({
    data,
    id,
    blockType: BlockNode.PromptBlockNode,
    fieldInput: prompt
  });

  const filledOutPrompt = useMemo(
    () => getPromptWithUserInput(prompt, userInput),
    [prompt, userInput]
  );

  return (
    <DrawerBottom
      mainContent={
        <>
          <TestFields
            fields={fields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestBlockOutput
            output={output}
            label={label}
            type={PromptTypeToOutputFieldMap[type]}
          />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
};

export default TestPrompt;
