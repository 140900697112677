import {
  InputSourceType,
  type ToolAssetValue,
  type TransformedInputValue
} from "@toolflow/shared";

function isToolAsset(value: TransformedInputValue): value is ToolAssetValue {
  return (
    value.sourceType === InputSourceType.ToolOutput ||
    value.sourceType === InputSourceType.ToolOutputPlaceholder
  );
}

export default isToolAsset;
