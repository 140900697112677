import {
  BlockNode,
  type UserInputDictType,
  type CustomToolInputField,
  type RunBlockToolData
} from "@toolflow/shared";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useHandleSocketMessage from "../../hooks/useHandleSocketMessage";
import useInnerBlockState from "../../hooks/useInnerBlockState";
import { useRunBlockMutation } from "../../../../../../ToolflowAPI/rtkRoutes/blockApi";
import { useState } from "react";
import arrayToObject from "../functions/arrayToObject";
import { useDispatch } from "react-redux";
import transformList from "../functions/transformList";
import { getIsOutOfCreditsError } from "../../../../../../ToolflowAPI/errors/getIsOutOfCreditsError";
import { setErrorMessage } from "../../../../../../stores/actions";
import { getErrorMessage } from "../../../../../../ToolflowAPI/errors/getErrorMessage";
import useRunButtonBase from "../../../../../tools/components/editorToolCard/authenticatedActions/hooks/useRunButtonBase";

const useTest = <T extends RunBlockToolData["blockData"]>({
  customField,
  blockType,
  fieldInput,
  data,
  id
}: {
  customField?: CustomToolInputField;
  blockType: BlockNode;
  fieldInput: string;
  data: T;
  id: string;
}) => {
  const { label } = data;
  const { dispatch, state } = useToolbuilderContext();
  const { loading, output, setLoading } = useHandleSocketMessage();
  const { abort, componentId, useFields } = useInnerBlockState();
  const fields = useFields(fieldInput, id);
  const [runBlock] = useRunBlockMutation();
  const { toolId, toolVersionId } = state;
  const [showUpgrade, setShowUpgrade] = useState(false);

  const [userInput, setUserInput] = useState<UserInputDictType>(
    arrayToObject(fields)
  );
  const reduxDispatch = useDispatch();

  const handleRunBlock = async () => {
    setLoading(true);
    dispatch({
      type: "UPDATE_TOOL_OUTPUT",
      response: null,
      percentCompleted: 0
    });

    const toolInputFields = transformList(fields, customField);
    const toolData: RunBlockToolData = {
      componentId,
      userInput: userInput,
      blockType,
      toolInputFields,
      blockData: data,
      trackingMetrics: {
        toolId,
        toolVersionId,
        blockId: id
      }
    };

    const handleError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        setShowUpgrade(true);
      }
      reduxDispatch(setErrorMessage(getErrorMessage(e, "Error running block")));
      setLoading(false);
    };

    try {
      await runBlock(toolData).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const { action, text, endIcon } = useRunButtonBase({
    send: handleRunBlock,
    abort,
    loading,
    runButtonText: "Test"
  });

  return {
    action,
    loading,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    fields,
    userInput,
    setUserInput
  };
};

export default useTest;
