import {
  FieldType,
  PrimaryOptionsDict,
  type FieldConfig,
  type PerplexityBlockProps
} from "@toolflow/shared";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../globalTheme/muiUtils/appTheme";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { getPerplexityModelOptions } from "../../helpers/priceDict";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";

function PerplexityBlockInner({
  data,
  id
}: Omit<PerplexityBlockProps, "selected">) {
  const { label, settings, optimizations } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.NewSelectInput,
        label: "Model",
        subtitle: "Select text generation technology",
        fieldKey: "llmModel",
        primaryKey: PrimaryOptionsDict.optimizations,
        options: getPerplexityModelOptions()
      },
      {
        type: FieldType.PromptInput,
        label: "Prompt",
        fieldKey: "input",
        maxRows: MAX_ROWS_LARGE_TEXTFIELD,
        minRows: MAX_ROWS_LARGE_TEXTFIELD
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
        fieldKey: "temperature",
        primaryKey: PrimaryOptionsDict.optimizations
      }
    ]
  };

  return (
    <>
      <FieldsByType
        id={id}
        configs={configs}
        settings={settings}
        optimizations={optimizations}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default PerplexityBlockInner;
