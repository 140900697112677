import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
  FieldType,
  type BlockSettingsBase,
  type BlockSettingsWithInputType,
  type FieldConfig,
  type LLMSettingsBase,
  type StructuredBlockSettings
} from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import JsonSchemaConstructor from "../jsonSchemaConstructor/JsonSchemaConstructor";
import PromptInputWrapper from "../PromptInputWrapper";
import SelectField from "../selectField/SelectField";
import InputTypeSelectWrapper from "../selectWithInput/InputTypeSelectWrapper";
import NewSelectField from "./fields/NewSelectField";
import SliderField from "./fields/SliderField";
import styles from "./fieldsByType.module.css";
import useFieldsByTypeHelper from "./useFieldsByTypeHelper";

const cx = parsedClassNames.bind(styles);

export default function FieldsByType({
  configs,
  settings,
  optimizations,
  id
}: {
  configs: FieldConfig;
  settings: BlockSettingsBase;
  optimizations?: LLMSettingsBase;
  id: string;
}) {
  const { getFieldKey, getFieldValue } = useFieldsByTypeHelper({
    settings,
    optimizations
  });

  return (
    <Stack spacing={2} divider={<Divider />}>
      {configs.fields.map((field, index) => {
        return (
          <Box key={index} className={cx("box-margin-inline")}>
            {field.type === FieldType.PromptInput && (
              <PromptInputWrapper
                id={id}
                label={field.label}
                value={getFieldValue(field, "")}
                fieldKey={field.fieldKey}
                maxRows={field.maxRows}
                minRows={field.minRows}
              />
            )}

            {field.type === FieldType.SelectInput && (
              <SelectField
                id={id}
                label={field.label}
                fieldKey={field.fieldKey}
                options={field.options}
                value={getFieldValue(field, "")}
              />
            )}

            {field.type === FieldType.CustomSelectInput && (
              <InputTypeSelectWrapper
                id={id}
                field={field}
                settings={settings as BlockSettingsWithInputType}
              />
            )}

            {field.type === FieldType.JsonSchemaInput && (
              <Grid container spacing={2}>
                <Grid item xs={12} className="m-h-8px">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h8">{field.label}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitleLarge">
                        {field.subLabel}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <JsonSchemaConstructor
                    id={id}
                    schema={(settings as StructuredBlockSettings).schema}
                  />
                </Grid>
              </Grid>
            )}

            {field.type === FieldType.Slider && (
              <SliderField
                id={id}
                label={field.label}
                subtitle={field.subtitle}
                value={getFieldValue(field, 0)}
                fieldKey={getFieldKey(field)}
              />
            )}

            {field.type === FieldType.NewSelectInput && (
              <NewSelectField
                id={id}
                label={field.label}
                subtitle={field.subtitle}
                options={field.options}
                value={getFieldValue(field, 0)}
                fieldKey={getFieldKey(field)}
              />
            )}
          </Box>
        );
      })}
    </Stack>
  );
}
