import React from "react";
import { Typography, Paper } from "@mui/material";
import { useBlockContext } from "../../BlockContext";
import BlockAlert from "./BlockAlert";
import DeleteNodeButton from "./deleteNodeButton/DeleteNodeButton";
import useSetBlockMessage from "../hooks/useSetBlockMessage";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import useGetDrawerNodeId from "../../store/hooks/useGetDrawerNodeId";
import styles from "./blockPaperHeader.module.css";
import BlockIconWrapper, { useBlockIcon } from "./blockIcons/BlockIcon";
import { blockPaperLabelDict } from "../constants/blockConfig";
import { toolflowTextSecondaryColor } from "../../../../../../globalTheme/muiUtils/appTheme";

const cx = parsedClassNames.bind(styles);

function BlockPaperHeader() {
  const { blockData: data, id } = useBlockContext();
  const BlockIcon = useBlockIcon(data);
  const secondaryLabel = blockPaperLabelDict[data.type];

  const { label } = data;

  useSetBlockMessage();
  const openNodeId = useGetDrawerNodeId();

  return (
    <Paper
      className={cx(
        {
          "border-on-hover": openNodeId !== id,
          "border-on-select": openNodeId === id
        },
        "cursor-pointer border-radius-8px bg-color-right-side-content",
        styles["paper-header-container"]
      )}
    >
      <Paper
        className={cx(
          "flex align-i-center justify-space-between p-8px border-radius-6px",
          styles["paper-header"]
        )}
        variant="outlined"
      >
        <div
          className="align-i-center flex w-100-percent"
          style={{ maxWidth: "88%" }}
        >
          {BlockIcon && (
            <BlockIconWrapper
              BlockIcon={
                <BlockIcon color={toolflowTextSecondaryColor} size={20} />
              }
            />
          )}
          <div className="m-l-8px flex flex-column">
            <Typography variant="chipSmall" color="text.secondary">
              {secondaryLabel}
            </Typography>
            <Typography variant="nodeHeader">{label}</Typography>
          </div>
        </div>
        <div className="flex align-i-center">
          <DeleteNodeButton />
        </div>
      </Paper>
      <BlockAlert />
    </Paper>
  );
}

export default BlockPaperHeader;
