import {
  PrimaryOptionsDict,
  type BlockSettingsBase,
  type FieldTypeConfigs,
  type LLMSettingsBase
} from "@toolflow/shared";

export default function useFieldsByTypeHelper({
  settings,
  optimizations
}: {
  settings: BlockSettingsBase;
  optimizations?: LLMSettingsBase;
}) {
  const optionsDict: {
    [key in PrimaryOptionsDict]:
      | BlockSettingsBase
      | LLMSettingsBase
      | undefined;
  } = {
    settings,
    optimizations
  };

  function getFieldValue(field: FieldTypeConfigs, defaultValue: $TSAllowedAny) {
    if ("primaryKey" in field && field.primaryKey) {
      return (
        optionsDict[field.primaryKey]?.[
          field.fieldKey as keyof (BlockSettingsBase | LLMSettingsBase)
        ] || defaultValue
      );
    } else if ("fieldKey" in field && field.fieldKey) {
      return settings[field.fieldKey as keyof BlockSettingsBase];
    }
    throw Error("Field must have a fieldKey");
  }

  function getFieldKey(field: FieldTypeConfigs) {
    if ("primaryKey" in field && field.primaryKey) {
      return `${field.primaryKey}.${field.fieldKey}`;
    } else if ("fieldKey" in field && field.fieldKey) {
      return `settings.${field.fieldKey}`;
    }
    throw Error("Field must have a fieldKey");
  }

  return { optionsDict, getFieldValue, getFieldKey };
}
