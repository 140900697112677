import { Document, LeanDocument } from "mongoose";
import { JSONContent } from "@tiptap/core";
import { UserSchemaId, WorkspaceSchemaId } from "../schemaTypes";
import {
  $TSFixMe,
  DallEOutputType,
  JSONValue,
  RowUserInputDictType,
  UserInputDictType
} from "../types";
import { VisibilityTypes } from "./ToolDocument";
import { SanitizedUserType } from "./UserDocument";

export type SheetInputValue = {
  sheetData: RowUserInputDictType[];
  selectedToolId: string;
};

export type InputValue = string | DallEOutputType | JSONValue | SheetInputValue;

// dallE2 should be migrated to generic dallE at some point
export enum InputValueType {
  String = "string",
  DallE = "dallE2",
  Structured = "structured",
  Sheet = "sheet"
}

export enum InputSourceType {
  Manual = "manual",
  ToolOutput = "toolOutput",
  ToolOutputPlaceholder = "toolOutputPlaceholder"
}

export type WorkflowInputString = {
  value: string | JSONContent;
  valueType: InputValueType.String;
} & WorkflowInputBase;

export type WorkflowInputDallE = {
  value: DallEOutputType;
  valueType: InputValueType.DallE;
} & WorkflowInputBase;

export type WorkflowInputStructured = {
  value: $TSFixMe;
  valueType: InputValueType.Structured;
} & WorkflowInputBase;

export type WorkflowInputSheet = {
  value: SheetInputValue;
  valueType: InputValueType.Sheet;
} & WorkflowInputBase;

export type WorkflowToolInputs =
  | WorkflowInputString
  | WorkflowInputDallE
  | WorkflowInputStructured;

export type WorkflowInput = WorkflowToolInputs | WorkflowInputSheet;

export type WorkflowInputBase = {
  id: string;
  name: string;
  source: InputSourceType;
  sourceIdentifier?: string;
  loading?: boolean;
  valueType?: InputValueType;
  data?: {
    toolId?: string;
    tvrId?: string;
    creatorId?: string;
    userInput?: UserInputDictType;
    outputId?: string;
    toolCount?: number;
  };
};

export type UpdateWorkspaceDataToSend = {
  name?: string;
  visibility?: VisibilityTypes;
  about?: string;
};

export type ContentType = $TSFixMe;

export type TWorkflowProgress = {
  inputs: UserInputDictType;
  outputs: { [key: string]: string };
};

export type TWorkflowProgressDict = {
  [key: string]: TWorkflowProgress;
};

export type TUseWorkflow = {
  activeStep: number;
  workflowProgress: TWorkflowProgressDict;
};

export type TWorkspaceDataToSave = {
  _id: string;
  name: string;
  about: string;

  visibility: VisibilityTypes;
  workflow: TUseWorkflow;

  inputs: WorkflowInput[];
  visibleAssetId: string;
  chatTab: {
    currentThreadId: string;
  };
  toolTab: {
    userInput: UserInputDictType;
  };
  entity: {
    entityId: string;
    componentId: string;
    entityType: "workflow" | "tool" | "";
  };
};

export type StreamlinedWorkspace = {
  _id: string;
  name: string;
  about: string;
  visibility: VisibilityTypes;
  updatedAt: number;
  createdAt: number;
};

export type Workspace = {
  creator: SanitizedUserType;
  userId: string;
  isDeleted: boolean;
  updatedAt: number;
  createdAt: number;
} & TWorkspaceDataToSave;

export type PopulatedWorkspace = Omit<Workspace, "userId" | "_id"> &
  Document & {
    userId: UserSchemaId;
    _id: WorkspaceSchemaId;
  };

export type TWorkspacePartial = Workspace | Partial<TWorkspaceDataToSave>;

export type WorkspaceDocument = Omit<PopulatedWorkspace, "creator"> & Document;
export type LeanPopulatedWorkspaceDocument = LeanDocument<PopulatedWorkspace>;
export type LeanWorkspaceDocument = LeanDocument<WorkspaceDocument>;
