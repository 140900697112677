import { Grid } from "@mui/material";
import {
  FieldType,
  type FieldConfig,
  type SerpBlockSettings
} from "@toolflow/shared";
import { FC } from "react";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import SubSettingsLayout from "../deepgram/SubSettingsLayout";
import ModelDemographicSettings from "./ModelDemographicSettings";
import ModelOutputSettings from "./ModelOutputSettings";
import ModelPaginationSettings from "./ModelPaginationSettings";

interface ISerpBlockSettingsProps {
  id: string;
  settings: SerpBlockSettings;
}
const SerpBlockSettingsComponent: FC<ISerpBlockSettingsProps> = (props) => {
  const { id, settings } = props;
  useSyncCurrentAndPrevSettings(settings);

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.CustomSelectInput,
        label: "Keywords",
        sourceLabel: "Select Source",
        fieldKey: "queries",
        inputValueType: "array",
        userFieldKey: "userQueriesFieldKey"
      }
    ]
  };

  return (
    <>
      <AccordionWrapper
        title="Configuration"
        className="marginBottom16"
        startsExpanded
        elevation={0}
      >
        <SubSettingsLayout
          title="Query Keywords"
          subheader="Keywords are used to get results from google search result. Each keyword will be separately searched in parallel. Keywords must be comma delimited."
        >
          <FieldsByType id={id} configs={configs} settings={settings} />
        </SubSettingsLayout>
        <SubSettingsLayout title="Model Settings">
          <Grid container spacing={2}>
            <ModelOutputSettings
              id={id}
              allowedOutputs={settings.allowedOutputs}
            />
            <ModelDemographicSettings id={id} settings={settings} />
            <ModelPaginationSettings id={id} settings={settings} />
          </Grid>
        </SubSettingsLayout>
      </AccordionWrapper>
    </>
  );
};

export default SerpBlockSettingsComponent;
