import type { WorkflowInputStructured } from "@toolflow/shared";
import { useInputContext } from "../../../../../../../utilities/contexts/InputContext";
import StructuredOutput from "../../../../../builder/toolBuilder/blocks/structuredBlock/structuredOutput/StructuredOutput";
import AssetSwitch from "../assetSizeSwitch/AssetSizeSwitch";
import StructuredAssetHeader from "./StructuredAssetHeader";

export default function StructuredAsset() {
  const { input } = useInputContext<WorkflowInputStructured>();

  return (
    <>
      <AssetSwitch header={<StructuredAssetHeader name={input.name} />}>
        <div className="asset-max-width m-h-auto scrollable-content max-h-100-percent">
          <StructuredOutput outputData={input.value} />
        </div>
      </AssetSwitch>
    </>
  );
}
