import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useUpdateField from "../../useUpdateField";
import { useId } from "react";

interface SelectFieldProps {
  id: string;
  value: string;
  fieldKey: string;
  label: string;
  options: string[];
}

export default function SelectField({
  id,
  value,
  fieldKey,
  options,
  label
}: SelectFieldProps) {
  const fieldId = useId();
  const updateField = useUpdateField(id);

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id={fieldId}>{label}</InputLabel>
      <Select
        labelId={fieldId}
        id={fieldKey}
        value={value}
        fullWidth
        label={label}
        onChange={(event) =>
          updateField(event.target.value, `settings.${fieldKey}`)
        }
        className="capitalize nowheel nodrag nopan"
      >
        {options.map((option) => (
          <MenuItem value={option} key={option} className="capitalize">
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
