import { CircularProgress } from "@mui/material";
import type { InputValueType } from "@toolflow/shared";
import {
  AssetIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  SheetIcon
} from "../../../../../../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";

const assetTypeToIcon = {
  string: AssetIcon,
  dallE2: ImageGenerationIcon,
  sheet: SheetIcon,
  structured: DataExtractorIcon
};

function AssetIndicator({
  loading,
  assetType
}: {
  loading?: boolean;
  assetType: InputValueType;
}) {
  if (loading) {
    return <CircularProgress size={14} />;
  } else {
    return (
      <RemixWrapperIconWithTheme
        Icon={assetTypeToIcon[assetType]}
        providedColor={toolflowTextDisabledColor}
        size={14}
      />
    );
  }
}

export default AssetIndicator;
