import PromptEditor from "../promptEditor/PromptEditor";
import useUpdateField from "../useUpdateField";

export default function PromptInputWrapper({
  id,
  label,
  value,
  fieldKey,
  helperText,
  maxRows,
  minRows
}: {
  id: string;
  label: string;
  value: string;
  fieldKey: string;
  helperText?: string;
  maxRows?: number;
  minRows?: number;
}) {
  const updateField = useUpdateField(id);

  return (
    <PromptEditor
      id={id}
      prompt={value}
      helperText={helperText}
      handleUpdatePrompt={(newValue) => {
        updateField(newValue, `settings.${fieldKey}`);
      }}
      maxRows={maxRows}
      minRows={minRows}
      label={label}
    />
  );
}
