import type { JSONSchemaItem } from "@toolflow/shared";
import TextFieldWithLabel from "../TextFieldWithLabel";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../globalTheme/muiUtils/appTheme";

export default function GuidelineSchemaField({
  schemaItem,
  onChange
}: {
  schemaItem: JSONSchemaItem;
  onChange(value: string): void;
}) {
  const guidelineHelperText = {
    string: "Give instructions to AI to extract the data",
    number: "Give instructions to AI to extract the data",
    integer: "Give instructions to AI to extract the data",
    boolean: "Give instructions to AI to extract the data",
    enum: "Describe how AI should apply preset options for categorization",
    array: "Give instructions to AI to extract the data",
    object: "Give instructions to AI to extract the data"
  };

  return (
    <TextFieldWithLabel
      fullWidth
      size="small"
      label="Extraction guidelines for AI"
      placeholder="Enter instructions here"
      variant="outlined"
      multiline
      maxRows={MAX_ROWS_LARGE_TEXTFIELD}
      value={schemaItem.guideline}
      helperText={
        schemaItem.type === "array"
          ? guidelineHelperText[schemaItem.itemType.type]
          : guidelineHelperText[schemaItem.type]
      }
      onChange={(event) => onChange(event.target.value)}
    />
  );
}
