import {
  FieldType,
  type FieldConfig,
  type ScraperBlockProps
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";

function ScraperBlockInner({ data, id }: Omit<ScraperBlockProps, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.PromptInput,
        label: "Url",
        fieldKey: "urlFieldInputKey",
        maxRows: 1,
        minRows: 1
      },
      {
        type: FieldType.SelectInput,
        label: "Preferred Result Type",
        fieldKey: "preferredScraperResultType",
        options: ["markdown", "html", "text"]
      }
    ]
  };

  return (
    <>
      <FieldsByType id={id} settings={settings} configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default ScraperBlockInner;
