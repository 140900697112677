import { Grid } from "@mui/material";
import type { JSONSchemaItem, JSONSchemaItemType } from "@toolflow/shared";
import SchemaTypeField from "../schemaFields/SchemaTypeField";
import TextFieldWithLabel from "../TextFieldWithLabel";

export default function MainSchemaFields({
  skipTypes,
  schemaItem,
  onChange
}: {
  skipTypes?: JSONSchemaItemType[];
  schemaItem: JSONSchemaItem;
  onChange(schemaItem: JSONSchemaItem): void;
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldWithLabel
          fullWidth
          size="small"
          label="Field label"
          value={schemaItem.fieldLabel}
          onChange={(event) => {
            const fieldLabel = event.target.value;
            onChange({ ...schemaItem, fieldLabel });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <SchemaTypeField
          schemaItem={schemaItem}
          skip={skipTypes}
          onChange={(item) => {
            onChange(item);
          }}
        />
      </Grid>
    </Grid>
  );
}
