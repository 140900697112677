import { CopyableField, InputValueType, PromptType } from "@toolflow/shared";

export const PromptTypeToOutputFieldMap = {
  [PromptType.DallE]: CopyableField.Image,
  [PromptType.ChatGPT]: CopyableField.LargeText,
  [PromptType.Anthropic]: CopyableField.LargeText,
  [PromptType.Structured]: CopyableField.Structured,
  [PromptType.Perplexity]: CopyableField.LargeText
};

export const OutputFieldToValueTypeMap = {
  [CopyableField.Image]: InputValueType.DallE,
  [CopyableField.LargeText]: InputValueType.String,
  [CopyableField.Structured]: InputValueType.Structured
};

/**
 * Returns InputValueType based on CopyableField.
 * @default InputValueType.String
 */
export function getValueTypeFromOutputField(copyableField: CopyableField) {
  return OutputFieldToValueTypeMap[copyableField] || InputValueType.String;
}

/**
 * Returns CopyableField based on PromptType.
 * @default CopyableField.LargeText
 */
export function getOutputFieldValueFromPromptType(promptType: PromptType) {
  return PromptTypeToOutputFieldMap[promptType] || CopyableField.LargeText;
}
