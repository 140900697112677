import React from "react";
import { usePromptEditorContext } from "./context/PromptEditorContext";
import type { CustomToolInputField } from "@toolflow/shared";
import AddInputDialog from "../addInput/AddInputDialog";

function PromptEditorAddInputDialog() {
  const {
    setOpenInputModal,
    openInputModal,
    typedChars,
    handleToolInputFieldClick,
    contextMenu,
    textFieldRef,
    setContextMenu
  } = usePromptEditorContext();

  const handleFromAdd = (field: CustomToolInputField) => {
    handleToolInputFieldClick(field.name);
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
    setTimeout(() => {
      if (textFieldRef.current && contextMenu) {
        textFieldRef?.current?.focus();
      }
    }, 0);
  };

  return (
    <AddInputDialog
      openModal={openInputModal}
      setOpenModal={setOpenInputModal}
      callback={handleFromAdd}
      closeCallback={handleCloseMenu}
      initialName={typedChars}
    />
  );
}

export default PromptEditorAddInputDialog;
