import {
  InputSourceType,
  type CustomToolOutputField,
  type ExternalToolFE,
  type WorkflowToolInputs
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { getValueTypeFromOutputField } from "../inputs/helpers/outputFieldMaps";
import useCurrentInputsRef from "./useCurrentInputsRef";
import useReplaceAssetArray from "./useReplaceAssetArray";

const useAddToolInputsToWorkspace = () => {
  const currentInputs = useCurrentInputsRef();
  const replaceAssetArray = useReplaceAssetArray();

  const addToolInputsToWorkspace = ({
    componentId,
    tool,
    addInputsCallback
  }: {
    componentId: string;
    tool: ExternalToolFE;
    addInputsCallback?: (
      id: string,
      outputField: CustomToolOutputField
    ) => void;
  }) => {
    const { _id: toolId, main, creator } = tool;
    const { toolOutputFields } = main;
    const oldInputs = currentInputs.current.filter(
      (i) => i.sourceIdentifier !== componentId
    );
    const savedInputs = currentInputs.current.filter(
      (i) => i.sourceIdentifier === componentId
    );

    const updatedInputs: WorkflowToolInputs[] = toolOutputFields.map(
      (outputField) => {
        // Check for a matching saved input
        // this will only be dalle or string
        const existingInput: WorkflowToolInputs | undefined = savedInputs.find(
          (i) =>
            i.data?.outputId === outputField.id &&
            i.sourceIdentifier === componentId &&
            i.valueType
        ) as WorkflowToolInputs | undefined;

        const valueType = getValueTypeFromOutputField(outputField.type);
        if (existingInput) {
          // Update existing input
          return {
            ...existingInput,
            name: outputField.name, // Updated name
            value: existingInput.value, // Keep existing value or modify as needed
            valueType
          } as WorkflowToolInputs;
        } else {
          const id = uuidv4();
          if (addInputsCallback) {
            addInputsCallback(id, outputField);
          }
          // Create new input
          return {
            id,
            name: outputField.name,
            value: "",
            valueType,
            source: InputSourceType.ToolOutputPlaceholder,
            sourceIdentifier: componentId,
            data: {
              toolId,
              creatorId: creator._id,
              outputId: outputField.id
            },
            loading: false
          } as WorkflowToolInputs;
        }
      }
    );
    const replacedArray = [...oldInputs, ...updatedInputs];
    replaceAssetArray(replacedArray);
  };
  return addToolInputsToWorkspace;
};

export default useAddToolInputsToWorkspace;
