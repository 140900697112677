import React from "react";
import { DeleteIcon } from "../../../../../../../globalTheme/icons/icons";
import useDeleteNodeButton from "./useDeleteNodeButton";

function DeleteNodeButton() {
  const deleteNode = useDeleteNodeButton();
  return (
    <div
      onClick={deleteNode}
      className="asset-button border-radius-6px flex align-i-center justify-center"
      style={{
        width: 22,
        height: 22
      }}
    >
      <DeleteIcon size={14} />
    </div>
  );
}

export default DeleteNodeButton;
