import { InputValueType, validateAndParseJson } from "@toolflow/shared";

export function getWorkspaceInputTypeFromValue(
  value: $TSFixMe
): InputValueType {
  if (typeof value === "string") {
    const isJsonString = validateAndParseJson(value);
    if (isJsonString) return InputValueType.Structured;
    else return InputValueType.String;
  } else return InputValueType.DallE;
}
