import {
  BlockType,
  UtilBlockType,
  type BlockDataTypesWithDefault,
  type DeepgramBlockSettings,
  type ScraperBlockSettings
} from "@toolflow/shared";
import type { Edge } from "reactflow";

const getDependencyArray = (data: BlockDataTypesWithDefault, edges: Edge[]) => {
  const { type } = data;
  if (type === UtilBlockType.Constant) {
    return [data.constant];
  }
  if (type === BlockType.Deepgram) {
    return [(data.settings as DeepgramBlockSettings).file];
  }
  if (type === BlockType.Scraper) {
    return [(data.settings as ScraperBlockSettings).urlFieldInputKey];
  }
  if (type === UtilBlockType.ToolWithinTool) {
    return [data.inputMap];
  }
  if (type === UtilBlockType.Logic) {
    return [JSON.stringify(data.logicArray), JSON.stringify(edges)];
  }
  return [];
};

export default getDependencyArray;
