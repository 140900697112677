import {
  AnthropicModel,
  OpenAiTextModel,
  PerplexityModel,
  type SelectOption
} from "@toolflow/shared";
import formatNumberToLocale from "../../../../../utilities/formatters/numbers/formatNumberToLocale";

type TOpenAIPriceDictType = {
  [key in OpenAiTextModel]: {
    tokenCount: number;
  };
};
type TAnthropicPriceDictType = {
  [key in AnthropicModel]: {
    tokenCount: number;
    label: string;
  };
};

type TPerplexityPriceDictType = {
  [key in PerplexityModel]: {
    tokenCount: number;
    label: string;
    skip?: boolean;
  };
};

// amount is input token max count , also referred in backend in TokenLengthValidation
export const openAIPriceDict: TOpenAIPriceDictType = {
  [OpenAiTextModel.GPT35]: {
    tokenCount: 16000
  },
  [OpenAiTextModel.GPT4]: {
    tokenCount: 8000
  },
  [OpenAiTextModel.GPT4Turbo]: {
    tokenCount: 128000
  },
  [OpenAiTextModel.GPT4o]: {
    tokenCount: 128000
  },
  [OpenAiTextModel.GPT4o_Aug]: {
    tokenCount: 128000
  }
  // "o1-mini": {
  //   tokenCount: 128000
  // },
  // "o1-preview": {
  //   tokenCount: 128000
  // }
};

export const anthropicPriceDict: TAnthropicPriceDictType = {
  [AnthropicModel.OPUS]: {
    tokenCount: 200000,
    label: "Claude 3 Opus"
  },
  [AnthropicModel.SONNET]: {
    tokenCount: 200000,
    label: "Claude 3 Sonnet"
  },
  [AnthropicModel.SONNET_3_5]: {
    tokenCount: 200000,
    label: "Claude 3.5 Sonnet"
  },
  [AnthropicModel.HAIKU]: {
    tokenCount: 200000,
    label: "Claude 3 Haiku"
  }
};

export const perplexityPriceDict: TPerplexityPriceDictType = {
  [PerplexityModel.SonarSmOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar SM"
  },
  [PerplexityModel.SonarLgOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar LG"
  },
  [PerplexityModel.SonarHgOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar HG"
  },
  [PerplexityModel.SonarSmChat]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar SM Chat",
    skip: true
  },
  [PerplexityModel.SonarLgChat]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar LG Chat",
    skip: true
  }
};

export function getPerplexityModelOptions() {
  return Object.entries(perplexityPriceDict).reduce((acc, [k, v]) => {
    if (!v.skip) {
      acc.push({
        value: k,
        label: v.label,
        subText: ` · ${formatNumberToLocale(v.tokenCount)} tokens`
      });
    }
    return acc;
  }, [] as SelectOption[]);
}
