import { TextField } from "@mui/material";
import {
  InputType,
  type CustomSelectInputFieldType,
  type BlockSettingsWithInputType
} from "@toolflow/shared";
import { isArray } from "lodash";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../globalTheme/muiUtils/appTheme";
import useUpdateField from "../../useUpdateField";
import SelectWithInput from "../selectWithInput/SelectWithInput";

export default function InputTypeSelectWrapper({
  id,
  field,
  settings
}: {
  id: string;
  field: CustomSelectInputFieldType;
  settings: BlockSettingsWithInputType;
}) {
  const { inputType } = settings;
  const updateField = useUpdateField(id);

  const isArrayInput = field.inputValueType === "array";
  const inputKeyValue =
    settings[field.fieldKey as keyof BlockSettingsWithInputType];
  const stringifiedInputValue =
    isArrayInput && isArray(inputKeyValue)
      ? inputKeyValue.join(",")
      : inputKeyValue;

  return (
    <SelectWithInput
      id={id}
      label={field.sourceLabel}
      settings={settings}
      inputTypeValue={
        inputType === InputType.FixedInput
          ? InputType.FixedInput
          : inputType === InputType.BlockInput
            ? stringifiedInputValue.replace("{{", "").replace("}}", "")
            : inputType === InputType.UserInput
              ? settings[field.userFieldKey as keyof BlockSettingsWithInputType]
              : inputType
      }
      inputKey={field.fieldKey}
      userFieldKey={field.userFieldKey}
      isArrayInput={isArrayInput}
    >
      <TextField
        label={field.label}
        multiline
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        value={stringifiedInputValue}
        required={inputType === InputType.FixedInput}
        onChange={(e) => {
          const value = isArrayInput
            ? e.target.value.split(",")
            : e.target.value;
          updateField(value, `settings.${field.fieldKey}`);
        }}
      />
    </SelectWithInput>
  );
}
