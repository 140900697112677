import { type Content } from "@tiptap/core";
import { InputSourceType, InputValueType } from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { useWorkspaceInputsFieldArrayContext } from "../../contexts/WorkspaceInputsContext";
import { VARIABLE_NAME } from "../../helpers/workspaceConstants";
import useSetVisibleAsset from "../useSetVisibleAsset";

function useAddNewStringAsset() {
  const { append } = useWorkspaceInputsFieldArrayContext();
  const setVisibleAsset = useSetVisibleAsset();

  const addNewAsset = (value?: Content) => {
    const id = uuidv4();
    append({
      name: `New ${VARIABLE_NAME.toLowerCase()}`,
      source: InputSourceType.Manual,
      value: value || "",
      valueType: InputValueType.String,
      id
    });
    setVisibleAsset(id);

    return id;
  };
  return addNewAsset;
}

export default useAddNewStringAsset;
