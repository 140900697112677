import { Button, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import type { ToolWithinToolBlockProps } from "@toolflow/shared";
import {
  CloseIcon,
  SearchIcon
} from "../../../../../../globalTheme/icons/icons";
import { useLazyGetToolQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import RemixButton from "../../../../../../utilities/components/icons/RemixButton";
import { SearchToolsDialog } from "../../../../../tools/components/searchToolsDialog/SearchToolsDialog";
import ToolInfo from "../../../../../tools/components/ToolInfo";
import ToolOutputsCard from "../../../../../tools/components/ToolOutputsCard";
import { ToolContextComponent } from "../../../../../tools/contexts/ToolContext";
import ToolVersionCard from "../../../../../toolVersion/ToolVersionCard";
import NavigateToToolInWorkspaceButton from "../../../NavigateToToolInWorkspaceButton";
import OutputMapper from "../../OutputMapper";
import useShouldWrap from "../../useShouldWrap";
import useUpdateField from "../../useUpdateField";
import getInitialInputMap from "./functions/getInitialInputMap";

// To refactor when we redesign this node
function ToolWithinToolBlockInner({
  data,
  id
}: Omit<ToolWithinToolBlockProps, "selected">) {
  const { tool, inputMap } = data;
  const [shouldWrap, wrapperRef] = useShouldWrap();

  const [getLazyTool, { isLoading }] = useLazyGetToolQuery();

  const updateField = useUpdateField(id);

  const resetTool = () => {
    updateField(null, "tool");
    updateField(null, "inputMap");
  };

  const updateTool = async (clickedId: string) => {
    if (!isLoading) {
      await getLazyTool(clickedId).then(({ data: toolResponseData }) => {
        if (toolResponseData?.tool) {
          const { tool: toolResponse } = toolResponseData;
          const initialInputMap = getInitialInputMap(toolResponse);
          updateField(toolResponse, "tool");
          updateField(initialInputMap, "inputMap");
        }
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!tool) {
    return (
      <div className="flex flex-column m-16px">
        <SearchToolsDialog
          onClickEntity={updateTool}
          disabledEntities={{ workflows: true }}
          title="Find tool"
          button={
            <Button
              variant="contained"
              size="medium"
              className="m-v-16px"
              startIcon={<SearchIcon size={20} />}
            >
              Select tool
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column m-16px">
      <ToolContextComponent tool={tool}>
        <Grid container spacing={2} className="m-v-16px" ref={wrapperRef}>
          <Grid xs={12}>
            <Card variant="elevation">
              <ToolInfo
                action={
                  <>
                    <NavigateToToolInWorkspaceButton
                      toolId={tool._id}
                      size="small"
                      variant="outlined"
                    />
                    <RemixButton onClick={resetTool} icon={CloseIcon} />
                  </>
                }
              />
            </Card>
          </Grid>
          <Grid xs={shouldWrap ? 6 : 12}>
            <OutputMapper data={data} id={id} />
          </Grid>
          <Grid xs={shouldWrap ? 6 : 12}>
            <ToolOutputsCard toolOutputs={tool.main.toolOutputFields} />
          </Grid>
          <Grid xs={12}>
            <ToolVersionCard tool={tool} blockId={id} inputMap={inputMap} />
          </Grid>
        </Grid>
      </ToolContextComponent>
    </div>
  );
}

export default ToolWithinToolBlockInner;
