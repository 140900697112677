import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import ToolbuilderOutputMenuSubheader from "./ToolbuilderOutputMenuSubheader";
import useGetCurrentToolOutputField from "./hooks/useGetCurrentToolOutputField";
import useGetCurrentToolOutputFieldIndex from "./hooks/useGetCurrentToolOutputFieldIndex";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";

const ChangeToolOutputNameSelect = ({
  disabled,
  index,
  name
}: {
  disabled?: boolean;
  index: number;
  name: string;
}) => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutputOptions: fields } = state.currentState;

  const handleToolOutputFieldChangeName = (value: string) => {
    const newField = [...fields.inputs, ...fields.blocks].filter(
      (f) => f.value === value
    )[0];
    dispatch({
      type: "UPDATE_TOOL_OUTPUT_FIELD_NAME",
      index,
      toolOutputField: newField
    });
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel>Field</InputLabel>
      <Select
        label="Field"
        fullWidth
        value={name}
        className="nowheel nodrag nopan"
        onChange={(e) => handleToolOutputFieldChangeName(e.target.value)}
      >
        <ToolbuilderOutputMenuSubheader secondary={`Inputs`} />
        {fields.inputs.map((field, idx) => (
          <MenuItem value={field.value} key={idx}>
            {field.value}
          </MenuItem>
        ))}
        <ToolbuilderOutputMenuSubheader secondary="Blocks" />
        {fields.blocks.map((field, idx) => (
          <MenuItem value={field.value} key={idx} disableRipple>
            {field.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function ChangeToolOutputNameSelectContainer({
  disabled
}: {
  disabled?: boolean;
}) {
  const name = useGetCurrentToolOutputField()?.name;
  const index = useGetCurrentToolOutputFieldIndex();

  return (
    <ChangeToolOutputNameSelect disabled={disabled} index={index} name={name} />
  );
}

export default ChangeToolOutputNameSelectContainer;
