import {
  InputSourceType,
  InputValueType,
  type THorizontal,
  type TVariableType,
  type TVertical,
  type TWorkspaceDataToSave,
  type VisibilityTypes
} from "@toolflow/shared";

export const WORKSPACE_NAME_FIELD_LABEL = "name";
export const WORKSPACE_ABOUT_FIELD_LABEL = "about";
export const WORKSPACE_VISIBILITY_FIELD_LABEL = "visibility";
export const WORKSPACE_ID_FIELD_LABEL = "_id";
export const WORKSPACE_INPUTS_FIELD_LABEL = "inputs";
export const WORKSPACE_CONTENT_FIELD_LABEL = "content";
export const WORKSPACE_VISIBLE_ASSET_ID = "visibleAssetId";

export const CHAT_TAB_BASE = "chatTab";
export const WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL =
  `${CHAT_TAB_BASE}.currentThreadId` as const;

export const WORKFLOW_USE_BASE = "workflow";
export const WORKSPACE_WORKFLOW_ID_FIELD_LABEL =
  `${WORKFLOW_USE_BASE}.workflowId` as const;
export const WORKSPACE_WORKFLOW_COMPONENT_ID_FIELD_LABEL =
  `${WORKFLOW_USE_BASE}.componentId` as const;
export const WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL =
  `${WORKFLOW_USE_BASE}.workflowProgress` as const;
export const WORKSPACE_WORKFLOW_ACTIVE_STEP_FIELD_LABEL =
  `${WORKFLOW_USE_BASE}.activeStep` as const;

export const DEFAULT_WORKSPACE_VISIBLITY = "private";
export const DEFAULT_WORKSPACE_NAME = "";
export const WORKSPACE_NAME_PLACEHOLDER = "New workspace";
export const WORKSPACE_NAME_REQUIRED_ERROR = "Please add a workspace name";

export const defaultWorkspaceName = "";
export const defaultPrivacy: VisibilityTypes = "private";

export const VARIABLE_NAME: TVariableType = "Asset";

export const TOOL_TAB_BASE = "toolTab";
export const TOOL_TAB_USER_INPUT_FIELD_LABEL =
  `${TOOL_TAB_BASE}.userInput` as const;

export const WORKSPACE_TAB_HEIGHT = "48px";

export const INPUT_TYPES = [
  InputValueType.String,
  InputValueType.DallE,
  InputValueType.Structured,
  InputValueType.Sheet
] as const;

export const INPUT_SOURCES = [
  InputSourceType.Manual,
  InputSourceType.ToolOutput,
  InputSourceType.ToolOutputPlaceholder
] as const;

export const VERTICAL: TVertical = "vertical";
export const HORIZONTAL: THorizontal = "horizontal";

export const MAX_PANE_COUNT = 2;

export const WORKSPACE_ENTITY_BASE = "entity";

export const WORKSPACE_ENTITY_ID_FIELD_LABEL =
  `${WORKSPACE_ENTITY_BASE}.entityId` as const;
export const WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL =
  `${WORKSPACE_ENTITY_BASE}.componentId` as const;
export const WORKSPACE_ENTITY_TYPE_FIELD_LABEL =
  `${WORKSPACE_ENTITY_BASE}.entityType` as const;

export const DEFAULT_WORKSPACE_ENTITY = {
  entityId: "",
  componentId: "",
  entityType: "" as const
};

const DEFAULT_TOOL_TAB = {
  userInput: {}
};

const DEFAULT_CHAT_TAB = {
  currentThreadId: ""
};

const DEFAULT_WORKFLOW = {
  activeStep: 0,
  workflowProgress: {}
};

const DEFAULT_VISIBLE_ASSET_ID = "";

export const DEFAULT_WORKSPACE: TWorkspaceDataToSave = {
  [WORKSPACE_ENTITY_BASE]: DEFAULT_WORKSPACE_ENTITY,
  [WORKFLOW_USE_BASE]: DEFAULT_WORKFLOW,
  [WORKSPACE_INPUTS_FIELD_LABEL]: [],
  [CHAT_TAB_BASE]: DEFAULT_CHAT_TAB,
  [WORKSPACE_VISIBLE_ASSET_ID]: DEFAULT_VISIBLE_ASSET_ID,
  [WORKSPACE_ID_FIELD_LABEL]: "",
  [WORKSPACE_NAME_FIELD_LABEL]: DEFAULT_WORKSPACE_NAME,
  [WORKSPACE_ABOUT_FIELD_LABEL]: "",
  [WORKSPACE_VISIBILITY_FIELD_LABEL]: DEFAULT_WORKSPACE_VISIBLITY,
  [TOOL_TAB_BASE]: DEFAULT_TOOL_TAB
};

export const FIELDS_TO_SET = [
  WORKSPACE_INPUTS_FIELD_LABEL,
  WORKSPACE_ID_FIELD_LABEL,
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_ABOUT_FIELD_LABEL,
  WORKSPACE_VISIBILITY_FIELD_LABEL,
  TOOL_TAB_BASE
] as const;
