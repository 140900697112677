import {
  type ChatGPTBlockData,
  type DallEBlockData,
  type DallEOutputType,
  OpenAiImageModel,
  type PromptBlockProps,
  type AnthropicBlockData
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableImage from "../../../../../tools/components/editorToolCard/outputs/CopyableImage/CopyableImage";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import Optimizations from "../../Optimizations";
import AnthropicOptimizations from "../../optimizations/AnthropicOptimizations";
import PromptEditorContainer from "../../promptEditor/PromptEditorContainer";
import DallEOptimizations from "./dalle/DallEOptimizations";
import SelectTextGenerationTech from "./SelectTextGenerationTech";

const dallECharObject = {
  [OpenAiImageModel.DALLE2]: 1000,
  [OpenAiImageModel.DALLE3]: 4000
};

function PromptBlockInner({ data, id }: Omit<PromptBlockProps, "selected">) {
  const { label, prompt, type } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const dallECharCount =
    type === "Dall-E2"
      ? dallECharObject[(data as DallEBlockData).optimizations.model]
      : undefined;

  return (
    <>
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <>
            {type === "Dall-E2" ? (
              <CopyableImage
                value={(toolOutput[label] as DallEOutputType) || []}
                name={label}
              />
            ) : (
              <CopyableLargeTextField
                value={(toolOutput[label] as string) || ""}
                name={label}
              />
            )}
          </>
        </AccordionWrapper>
      )}

      {(type === "ChatGPT" || type === "Anthropic") && (
        <AccordionWrapper title="Model" elevation={0}>
          <SelectTextGenerationTech id={id} blockType={type} />
          {type === "ChatGPT" && (
            <Optimizations
              id={id}
              optimizations={(data as ChatGPTBlockData).optimizations}
            />
          )}
          {type === "Anthropic" && (
            <AnthropicOptimizations
              id={id}
              anthropicOptimizations={
                (data as AnthropicBlockData).anthropicOptimizations
              }
            />
          )}
        </AccordionWrapper>
      )}
      {type === "Dall-E2" && (
        <DallEOptimizations
          id={id}
          optimizations={(data as DallEBlockData).optimizations}
        />
      )}
      <AccordionWrapper elevation={0} title="Prompt" startsExpanded>
        <PromptEditorContainer
          id={id}
          prompt={prompt}
          maxLength={dallECharCount}
          helperText={
            dallECharCount
              ? `${prompt.length} / ${dallECharCount} characters. If you are piping in a
        response from another prompt, you may want to summarize the response to be
        fewer than ${dallECharCount} characters.`
              : undefined
          }
        />
      </AccordionWrapper>
    </>
  );
}

export default PromptBlockInner;
