import type { JSONSchemaItem } from "@toolflow/shared";
import { structureJsonSchemaInitialState } from "../../context/initialStates/structuredInitialState";
import useUpdateField from "../../useUpdateField";

export default function useJsonSchemaConstructor({
  id,
  schema
}: {
  id: string;
  schema: JSONSchemaItem[];
}) {
  const updateField = useUpdateField(id);

  function addSchemaItem() {
    const newSchema = [...schema];
    newSchema.push({ ...structureJsonSchemaInitialState });
    updateField(newSchema, "settings.schema");
  }

  function removeIndex(index: number) {
    const newSchema = schema.filter((_, i) => i !== index);
    updateField(newSchema, "settings.schema");
  }

  function updateSchemaItem(index: number, schemaItem: JSONSchemaItem) {
    const newSchema = [...schema];
    newSchema[index] = schemaItem;
    updateField(newSchema, "settings.schema");
  }

  return {
    removeIndex,
    addSchemaItem,
    updateSchemaItem
  };
}
